<template>
  <!-- Cambiar a un table -->
  <div>
    <portal-target name="notificationSuficienciaAlertPlace" />

    <!-- Lista de items -->
    <b-overlay :show="isLoading">
      <template #overlay>
        <loading />
      </template>
      <b-table
      :fields="fields"
      :items="list"
      show-empty
      responsive
      striped
      bordered
      hover
      small
      :busy="isLoading"
    >
      <template #cell(id)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item }}
        </div>
      </template>
      <template #cell(concepto)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item.concepto }}
        </div>
      </template>
      <template #cell(fecha)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
        </div>
      </template>
      <template #cell(folio)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item.folio }}
        </div>
      </template>
      <template #cell(mes)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="secondary" class="ml-2">
            {{ getMonthById(data.item.mes).value }}
          </b-badge>
        </div>
      </template>
      <template #cell(periodo_fiscal_id)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="primary" class="ml-2">{{ data.item.periodo_fiscal_id }}</b-badge>
        </div>
      </template>
      <template #cell(total)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ $formatNumber(data.item.total) }}
        </div>
      </template>

      <template #row-details="row">
        <suficiencia-movimientos-list
          :suficiencia_id="row.item.id"
        ></suficiencia-movimientos-list>
      </template>

      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
          <b-button
            class="mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="info"
            @click="row.toggleDetails()"
          >
            Movimientos
          </b-button>

          <b-button
            class="mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="dark"
            @click="onSelect(row.item.id)"
          >
            Importar
          </b-button>

          <b-button
            class="mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="primary"
            @click="onExport(row.item.id, row.item.folio)"
          >
            Exportar
          </b-button>

          <b-button
            class="mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="warning"
            @click="onTraspaso(row.item.id)"
          >
            Traspaso
          </b-button>

          <u-d-controls
            editPath="suficienciaPresupuestalModule/getSuficienciaById"
            deletePath="suficienciaPresupuestalModule/deleteSuficienciaById"
            :id="row.item.id"
            :editPermissions="['modificar suficiencia presupuestal']"
            :deletePermissions="['eliminar suficiencia presupuestal']"
            double-confirmation
            :doubleConfirmationParams="{ confirmation: true }"
            @on-deleted="onDeleteItem"
          >
            <template #edit>
              <b-button
                class="btn-sm btn-outline-blue mr-3 mb-2 mb-sm-0"
                variant="white"
                v-b-toggle.suficienciaFormSidebar
                @click="onEditItem(row.item.id)"
              >
                Editar
              </b-button>
            </template>
          </u-d-controls>
        </div>
      </template>
    </b-table>
    </b-overlay>

    <!-- Sidebar para importar y asociar el layout a un registro de nómina -->
    <b-sidebar
      id="importSuficienciaPoliza"
      right
      shadow
      backdrop
      no-header
      no-close-on-backdrop
      v-model="showSidebar"
      aria-labelledby="sidebar-no-header-title"
    >
      <template #default="{ hide }">
        <!-- Form para importar layout de nómina -->
        <div class="p-3">
          <h4 id="sidebar-no-header-title">Importar layout de suficiencia presupuestal</h4>
          <suficiencia-import
            v-if="Boolean(selectedSuficiencia)"
            :traspaso="selectedSuficiencia"
            @on-result="hide()"
          >
            <template #btn-cancel-place="{clearForm}">
              <b-button
                variant="dark"
                size="sm"
                @click="
                  clearForm()
                  hide()
                "
              >
                Cerrar
              </b-button>
            </template>
          </suficiencia-import>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SuficienciaImport from '@/components/SuficienciaPresupuestal/SuficienciaImport'
import SuficienciaMovimientosList from '@/components/SuficienciaPresupuestal/SuficienciaMovimientosList'

const payload = () => ({
  periodo_fiscal_id: null
})

export default {
  name: 'SuficienciaList',

  components: {
    SuficienciaImport,
    SuficienciaMovimientosList
  },

  props: {
    mes: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      payload: payload(),

      isLoading: false,

      list: [],

      selectedSuficiencia: null,

      showSidebar: false,

      fields: ['folio', 'fecha', 'concepto', 'total', 'acciones'],
      items: []
    }
  },

  computed: {
    ...mapState('suficienciaPresupuestalModule', ['suficienciaList']),

    ...mapGetters('suficienciaPresupuestalModule', ['getSuficienciaByMonth']),

    ...mapGetters('UIModule', ['getMonthById'])
  },

  watch: {
    suficienciaList: {
      immediate: true,
      deep: true,
      handler () {
        this.list = this.getSuficienciaByMonth(this.mes) || []
      }
    }
  },

  async created () {
    await this.init()
  },

  methods: {
    async init () {
      const periodoActivo = await this.$getPeriodoFiscalActivo()

      this.payload.periodo_fiscal_id = periodoActivo.id
    },

    onEditItem (id) {
      //
      // Manda un flag para indicar que debe esperar que el item cargue.
      this.$store.commit('suficienciaPresupuestalModule/setWaitToEditItem', true)

      //
      // Obtiene el item desde un dispatch
      this.$store.dispatch('suficienciaPresupuestalModule/getSuficienciaById', id)
    },

    onDeleteItem (data) {
      this.$alert(data, 'Suficiencia presupuestal.', 'notificationSuficienciaAlertPlace', {
        status: data.error ? 'danger' : 'success'
      })

      this.loadList()
    },

    onSelect (id) {
      this.selectedSuficiencia = this.list.find(el => el.id === id)

      this.showSidebar = true
    },

    onShowMovimientos (id) {
      this.selectedSuficiencia = this.list.find(el => el.id === id)
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch(
        'suficienciaPresupuestalModule/exportarSuficienciaPresupuestal',
        id
      )

      await this.$onDownload(getval, `suficiencia-presupuestal.${folio}.xlsx`)

      this.$notify({
        message: `Solicitud de descarga de suficiencia presupuestal con folio ${folio}.`
      })
    },

    async onTraspaso (id) {
      const getval = await this.$store.dispatch(
        'suficienciaPresupuestalModule/traspasoSuficienciaPresupuestal',
        id
      )

      this.$notify({
        message: getval.message
      })
    }
  }
}
</script>
